import React from 'react';
import {UserProvider} from '@auth0/nextjs-auth0/client';
import {datadogLogs} from '@datadog/browser-logs';
import {datadogRum} from '@datadog/browser-rum';
import {GoogleTagManager} from '@next/third-parties/google';
import {createSyncStoragePersister} from '@tanstack/query-sync-storage-persister';
import {QueryClient} from '@tanstack/react-query';
import {PersistQueryClientProvider} from '@tanstack/react-query-persist-client';
import App, {AppProps} from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import {SWRConfig} from 'swr';

import {sanitizeInnerHtml} from '@zoefin/design-system';
import {getUser} from '@zoefin/services/api/core';
import {ErrorBoundary} from '@zoefin/utils';

import '../../../utils/polyfills';

import {grid, initial} from '../../../components/commons/styles';
import {
  sessionReplaySampleRate,
  sessionSampleRate,
  telemetrySampleRate,
  traceSampleRate,
} from '../../../constants/datadog';
import HistoryProvider from '../../../stores/HistoryContext';
import localStorageProvider from '../../../utils/localStorageProvider';
import {addToDataLayer} from '../../../utils/pageUtils';

// Importing design system css needed to avoid flickering
import '@zoefin/design-system/dist/esm/index.css';
// Global styles
import 'slick-carousel/slick/slick.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const LOADING_OVERLAY_STYLE: React.CSSProperties = {
  position: 'absolute',
  zIndex: 999,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: '#ffffff',
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours catching
    },
  },
});

/**
 * Returns the localStorage object if available, or undefined if not.
 *
 * @description Fix for Chrome incognito (with "Block third-party cookies" enabled) and Safari private mode.
 * @error Uncaught SecurityError: Failed to read the 'localStorage' property from 'Window': Access is denied for this document.
 *
 * @returns The localStorage object or undefined.
 */
const getLocalStorage = () => {
  try {
    return window.localStorage;
  } catch {
    return undefined;
  }
};

const persister = createSyncStoragePersister({
  storage: typeof window !== 'undefined' && getLocalStorage(),
});

// START - Datadog init
datadogRum.init({
  applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
  clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
  site: process.env.NEXT_PUBLIC_DATADOG_SITE,
  service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
  env: process.env.NEXT_PUBLIC_DATADOG_ENVIRONMENT,
  version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  silentMultipleInit: true,
  traceSampleRate,
  telemetrySampleRate,
  sessionSampleRate,
  sessionReplaySampleRate,
  trackResources: true,
  trackLongTasks: true,
  trackViewsManually: true,
  trackUserInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
  trackSessionAcrossSubdomains: true,
  allowedTracingUrls: [
    (url) =>
      process.env.NEXT_PUBLIC_DATADOG_HOSTS?.split(',').some((host) =>
        url.includes(host),
      ),
  ],
});

datadogLogs.init({
  clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
  site: process.env.NEXT_PUBLIC_DATADOG_SITE,
  service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
  env: process.env.NEXT_PUBLIC_DATADOG_ENVIRONMENT,
  version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  silentMultipleInit: true,
  forwardErrorsToLogs: true,
  sessionSampleRate,
  trackSessionAcrossSubdomains: true,
  useCrossSiteSessionCookie: true,
});
// END - Datadog init

class PortalApp extends App<AppProps<CustomAppProps>, unknown> {
  captureException = async (error: Error, info: any) => {
    const renderingError = new Error(error.message);

    renderingError.name = `ReactRenderingError`;
    renderingError.stack = info?.componentStack || error?.stack;
    renderingError.cause = error;

    datadogRum.addError(renderingError, {
      tags: ['_app-capture-exception'],
    });
    datadogLogs.logger.error(
      renderingError.message,
      {tags: ['_app-capture-exception']},
      error,
    );

    const {user} = await getUser();

    addToDataLayer({
      overrideBase: 'renderingError',
      values: {
        User_Id: user?.identifier,
        User_Role: user?.role,
      },
    });
    datadogRum.addAction('renderingError', {
      User_Id: user?.identifier,
      User_Role: user?.role,
    });
  };

  render(): React.ReactElement {
    const {Component, pageProps} = this.props;

    return (
      <>
        <Head>
          <title>Zoe Financial</title>
          <meta
            name="description"
            content="Let Zoe Financial connect you with an independent, fee-only financial advisor to help you create an active, purposeful relationship with your money."
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
          />
          <meta name="robots" content="noindex,nofollow" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          {/* Google Tag Manager */}
          {sanitizeInnerHtml(
            // Instantiate Google Tag Manager `gtag` script globally
            `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'AW-879980004');`,
            'script',
          )}
          {/* End Google Tag Manager */}
        </Head>

        {/* Google Tag Manager (noscript) */}
        <noscript>
          <iframe
            title="google tag manager"
            src="https://www.googletagmanager.com/ns.html?id=GTM-5FZWLQ6"
            height="0"
            width="0"
            style={{display: 'none', visibility: 'hidden'}}
          />
        </noscript>
        {/* End Google Tag Manager (noscript) */}

        {/* White screen that disappear once Next.js render styles */}
        <div
          id="coverScreen"
          style={LOADING_OVERLAY_STYLE}
          className="loading-overlay"
        />
        <ErrorBoundary
          captureException={this.captureException}
          environment={process.env.VERCEL_ENV}>
          <HistoryProvider>
            <PersistQueryClientProvider
              client={queryClient}
              persistOptions={{persister}}>
              <SWRConfig value={{provider: localStorageProvider}}>
                <UserProvider>
                  <Component {...pageProps} />
                </UserProvider>
              </SWRConfig>
            </PersistQueryClientProvider>
          </HistoryProvider>
        </ErrorBoundary>

        <GoogleTagManager gtmId="GTM-5FZWLQ6" />

        <Script
          src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}&libraries=places&callback=Function.prototype`}
        />
        <Script src={process.env.NEXT_PUBLIC_DOCUSIGN_CDN} />
        <style jsx>{grid}</style>
        <style jsx>{initial}</style>
      </>
    );
  }
}

export default PortalApp;
