import css from 'styled-jsx/css';

import {styles} from '@zoefin/design-system';

const {color, typography} = styles;

export const grid = css.global`
  .grid {
    display: grid;
    grid-column-gap: 0;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    margin: 0 auto;

    @media screen and (min-width: 1024px) {
      display: grid;
      grid-column-gap: 1.6%;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      margin: 0 auto;
      max-width: 1366px;
      padding: 0 16px;
    }
  }

  .bar-loading {
    &__load {
      background: #ecfff6;
      border-radius: 20px;
      height: 16px;
      margin: 12px 0;
      overflow: hidden;
      position: relative;
      width: 230px;
    }

    &__progress {
      animation-fill-mode: both;
      animation: progressBar 2s ease-in-out;
      background-color: ${color.green};
      display: block;
      height: 100%;
    }

    @keyframes progressBar {
      0% {
        width: 0;
      }

      100% {
        width: 100%;
      }
    }
  }

  .sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  body.scroll-disabled {
    overflow: hidden;
  }
`;

export const initial = css.global`
  $darkBlue: #48465b;
  $darkGreen: #017ca2;

  html {
    width: 100%;
    height: 100%;
  }

  body {
    width: 100%;
    height: 100%;
    margin: 0;
    font-size: 16px;
    font-family: ${typography.type.primary};
    text-rendering: optimizespeed;
    color: $darkBlue;
    background-color: ${color.lighter};
    overflow-x: hidden;
  }

  *:focus {
    outline: none;
  }

  body p {
    line-height: 25px;
  }

  #__next {
    height: 100%;
    display: block;
  }

  .loading-overlay {
    display: none;
  }

  .form-group .form-control {
    font-size: 24px;
    height: calc(24px + 24px + 2px);
  }

  .form-group label {
    font-size: 20.8px;
  }

  .form-group .form-text {
    font-size: 20.8px;
  }

  .modal .modal-dialog .modal-content .modal-header {
    justify-content: center;
    border: 0;
  }

  .modal .modal-dialog .modal-content .modal-header .close::before {
    font-size: 24px;
  }

  .modal .modal-content .modal-header .modal-title {
    font-size: 27.2px;
    color: $darkGreen;
    text-align: center;
    width: 100%;
  }

  .modal-dialog .modal-content {
    padding: 4% 6%;
  }

  .modal .modal-dialog .modal-content .modal-header .close {
    position: absolute;
    top: 35px;
    right: 6.5%;
    height: 32px;
    width: 32px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(97, 95, 113);
    border-image: initial;
    border-radius: 40px;
    padding: 0;
  }

  .modal-dialog .btn-primary {
    width: 100%;
    margin-top: 15px;
    font-size: 19.2px;
    background-color: ${color.bluePrimary};
    border: 2px solid ${color.bluePrimary};
    border-image: initial;
  }

  .modal-dialog .btn-primary:hover {
    background-color: white;
    color: ${color.bluePrimary};
  }

  @media (min-width: 1024px) {
    .form-group label {
      font-size: 17.6px;
    }

    .form-group .form-control {
      font-size: 19.2px;
      height: calc(24px + 20.8px + 2px);
    }

    .form-group .form-text {
      font-size: 17.6px;
    }
  }

  @media (min-width: 820px) {
    #__next {
      display: flex;
    }
  }
`;
