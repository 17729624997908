const numbersOnlyRegex = /^\d+$/;

export const traceSampleRate = numbersOnlyRegex.test(
  process.env.NEXT_PUBLIC_DATADOG_TRACE_SAMPLE_RATE,
)
  ? Number(process.env.NEXT_PUBLIC_DATADOG_TRACE_SAMPLE_RATE)
  : 30;
export const telemetrySampleRate = numbersOnlyRegex.test(
  process.env.NEXT_PUBLIC_DATADOG_TELEMETRY_SAMPLE_RATE,
)
  ? Number(process.env.NEXT_PUBLIC_DATADOG_TELEMETRY_SAMPLE_RATE)
  : 30;
export const sessionSampleRate = numbersOnlyRegex.test(
  process.env.NEXT_PUBLIC_DATADOG_SESSION_SAMPLE_RATE,
)
  ? Number(process.env.NEXT_PUBLIC_DATADOG_SESSION_SAMPLE_RATE)
  : 30;
export const sessionReplaySampleRate = numbersOnlyRegex.test(
  process.env.NEXT_PUBLIC_DATADOG_REPLAY_SAMPLE_RATE,
)
  ? Number(process.env.NEXT_PUBLIC_DATADOG_REPLAY_SAMPLE_RATE)
  : 30;
