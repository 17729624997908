/* START OF POLYFILLS */

// IE polyfill
import 'core-js/es/array/find';
import 'core-js/es/array/includes';
import 'core-js/es/number/is-nan';
// IE 10 polyfill
import 'core-js/es/map';
import 'core-js/es/set';
// IE 11 polyfill
import 'core-js/es/string/includes';
import 'core-js/es/array';
// Function.name polyfill
import 'function.name-polyfill';
// window.scroll | element.scrollIntoView | element.scrollBy
import 'scroll-behavior-polyfill';

// IE startsWith() Polyfill
if (!String.prototype.startsWith) {
  String.prototype.startsWith = function startsWith(search, pos) {
    return this.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search;
  };
}

// IE Math.trunc Polyfill
if (!Math.trunc) {
  Math.trunc = function trunc(v) {
    return v < 0 ? Math.ceil(v) : Math.floor(v);
  };
}

// IE isInteger() Polyfill
Number.isInteger =
  Number.isInteger ||
  function isInteger(value) {
    return (
      typeof value === 'number' &&
      Number.isFinite(value) &&
      Math.floor(value) === value
    );
  };

/* END OF POLYFILLS */
