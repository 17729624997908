import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';
import {datadogRum} from '@datadog/browser-rum';
import {NextRouter, useRouter} from 'next/router';

import Storage from '../../utils/Storage';
import {PORTAL_HISTORY} from '../../utils/StorageConstants';

export interface HistoryContextValue {
  history: string[];
}

const missingProvider = 'You forgot to wrap your app in <HistoryProvider>';

export const HistoryContext = createContext<HistoryContextValue>({
  get history(): never {
    throw new Error(missingProvider);
  },
});

export const historyStorage = new Storage<string[]>(PORTAL_HISTORY, []);

function handleHistory({query, asPath, route}: NextRouter): string[] {
  const history = historyStorage.get();

  // If no previous history and `hosted_source` is coming as query parameter
  if (!history.length && !!query?.hosted_source?.length) {
    history.push(query.hosted_source.toString());
  }

  // Add current path
  if (asPath !== history[history.length - 1]) {
    history.push(asPath);

    historyStorage.set(history);
  }

  // Track page changes with Datadog (without query params or hashes)
  datadogRum.startView(route);

  return history;
}

const HistoryProvider: FC<PropsWithChildren> = ({children}) => {
  const router = useRouter();

  const history = useMemo(() => {
    // Handle page changes
    return handleHistory(router);
  }, [router]);

  const contextValue = useMemo(() => ({history}), [history]);

  return (
    <HistoryContext.Provider value={contextValue}>
      {children}
    </HistoryContext.Provider>
  );
};

export default HistoryProvider;

export function useHistory(): HistoryContextValue {
  return useContext<HistoryContextValue>(HistoryContext);
}
