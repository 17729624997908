import animateScrollTo from 'animated-scroll-to';

import {AddToDataLayer, GetFromPageContext} from './index.d';

export const getFromPageContext: GetFromPageContext = (ctx) => {
  const protocol = String(
    ctx?.req?.headers['x-forwarded-proto'] || 'http',
  ).split(',')[0];
  const baseURL = ctx?.req ? `${protocol}://${ctx.req.headers.host}` : '';
  const headers = {cookie: ctx?.req ? ctx.req.headers.cookie || '' : ''};

  return {...ctx, baseURL, headers};
};

export const inputNameToCamelCase: (
  text: string,
  uppercaseFirstLetter?: boolean,
) => string = (text, uppercaseFirstLetter = false) => {
  const sections = text.split('_');
  const fixedSections = sections.map((section, idx) => {
    if (idx === 0 && !uppercaseFirstLetter) {
      return section;
    }

    return section[0].toUpperCase() + section.slice(1);
  });

  return fixedSections.join('');
};

export const stringToCamelCase: (props: {
  text: string;
  join?: string;
  uppercaseFirst?: boolean;
}) => string = ({text, join, uppercaseFirst}) => {
  const sections = text.split('_');
  const fixedSections = sections.map((section, idx) => {
    if (idx === 0 && !uppercaseFirst) {
      return section;
    }

    return section[0].toUpperCase() + section.slice(1);
  });

  return fixedSections.join(join || '');
};

const getDLBase: () => string = () => {
  const reschedulePath = '/reschedule/';

  // Pathnames without numbers (ie: /[advId])
  const pathNames: ObjectLiteral = {
    '/dashboard': 'FAAPO',
    '/onboarding': 'FAAPO',
    '/onboarding/short': 'FAAPO',
    '/onboarding/start': 'FAAPO',
    '/onboarding/start/schedule': 'FAAPO',
    '/dashboard/advisors': 'FAAPM',
    '/onboarding/advisors': 'FAAPM',
    '/dashboard/advisors/': 'FAAPP',
    '/onboarding/advisors/': 'FAAPP',
    '/advisor/': 'FAAIP',
    [reschedulePath]: 'FAAR',
  };

  let cleanedPathname = '';

  // If reschedule (no numeric `id` but alpha-numeric `identifier`)
  if (window.location.pathname.indexOf(reschedulePath) !== -1) {
    cleanedPathname = reschedulePath;
  } else {
    cleanedPathname = window.location.pathname.replace(/\d/g, '');
  }

  if (cleanedPathname.indexOf('/onboarding/partners/') !== -1) {
    return 'FAAPO';
  }

  return window ? pathNames[cleanedPathname] || 'FAAP' : 'FAAP';
};

export const addToDataLayer: AddToDataLayer = ({
  overrideBase,
  event,
  values,
}) => {
  if (window) {
    const eventBase = overrideBase || getDLBase();
    const eventName = eventBase + (event ? `_${event}` : '');

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventName,
      ...(values || {}),
    });

    const currentHost = `${window.location.protocol}//${window.location.hostname}`;
    const allowedHosts: string[] = JSON.parse(
      process.env.BANKRATE_IFRAME_ALLOWED_HOSTS || '[]',
    );

    /**
     * As we can't predict Vercel previews URL, we need to programmatically add it
     * as "allowed" so any testing form can be fully functional
     *
     * `VERCEL_ENV` is provided by Vercel in deployment time. It's not required for local development.
     * `production` is only assigned to production deployments, never for previews.
     */
    if (
      process.env.VERCEL_ENV !== 'production' &&
      !allowedHosts.includes(currentHost)
    ) {
      allowedHosts.push(currentHost);
    }

    const currentURL = new URL(window.location.href);
    const embeddedHost = currentURL.searchParams.get('embed_host');

    // Send FAAF `form_entry` events to parent page (if embedded iframe)
    if (
      eventName === 'form_entry' &&
      embeddedHost &&
      allowedHosts.includes(embeddedHost)
    ) {
      window.parent.postMessage(
        {
          event: eventName,
          ...(values || {}),
        },
        embeddedHost,
      );
    }
  }
};

/**
 * Wrapper for `window.gtag` method. Globally defined in `_app`
 */
export function gtag(...args: any[]) {
  window.gtag =
    window.gtag ||
    function gtagBackup(...argsBackup: any[]) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(...argsBackup);
    };

  window.gtag(...args);
}

export const scrollBehavior = (
  targetElement: Window | Document | Element,
  offsetValue: number,
): void => {
  window.onbeforeunload = () => {
    window.scrollTo(0, 0);
  };

  const optionsScroll = {
    speed: 1500,
    minDuration: 250,
    maxDuration: 1500,
    element: targetElement,
    cancelOnUserAction: true,
  };

  const desireOffset = offsetValue;

  if (window.onbeforeunload !== null) {
    setTimeout(() => animateScrollTo(desireOffset, optionsScroll), 400);
  }
};
