import Router from 'next/router';

const localStorageProvider = (): Map<string, unknown> => {
  try {
    // When initializing, we restore the data from `localStorage` into a map.
    if (typeof window !== 'undefined') {
      if (Router.route.includes('/login')) {
        localStorage.removeItem('app-cache');

        return new Map();
      }

      const map = new Map<string, unknown>(
        JSON.parse(localStorage.getItem('app-cache') || '[]'),
      );

      // Before unloading the app, we write back all the data into `localStorage`.
      window.addEventListener('beforeunload', () => {
        const appCache = JSON.stringify(Array.from(map.entries()));

        try {
          localStorage.setItem('app-cache', appCache);
        } catch {
          return null;
        }

        return null;
      });

      // We still use the map for write & read for performance.
      return map;
    }

    return new Map();
  } catch {
    return new Map();
  }
};

export default localStorageProvider;
